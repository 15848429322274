:root {
    // Mobile Padding
    --mobile-main-main-padding-left: 32px;
    --mobile-main-main-padding-right: 32px;

    // Tablet Padding
    --tablet-main-main-padding-left: 100px;
    --tablet-main-main-padding-right: 100px;

    // Desktop Padding
    --desktop-main-main-padding-left: 100px;
    --desktop-main-main-padding-right: 100px;
}