:root {

    // =================================== Body Text ==================================

    // Colors
    --bodytext-bodytext-text-color: var(--text-color);

    // Fonts
    --bodytext-bodytext-font-family: var(--main-font);

    // Mobile Fonts
    --mobile-bodytext-body-text-font-size: var(--mobile-body-font-size);
    --mobile-bodytext-body-text-line-height: var(--mobile-body-line-height);
    --mobile-bodytext-body-text-letter-spacing: var(--mobile-body-letter-spacing);
    --mobile-bodytext-body-text-font-weight: var(--mobile-body-font-weight);

    // Tablet/Desktop
    --tablet-bodytext-body-text-font-size: var(--tablet-body-font-size);
    --tablet-bodytext-body-text-line-height: var(--tablet-body-line-height);
    --tablet-bodytext-body-text-letter-spacing: var(--tablet-body-letter-spacing);
    --tablet-bodytext-body-text-font-weight: var(--tablet-body-font-weight);

}