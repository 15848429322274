.small-loading {
    border: 4px solid;
    border-top: 4px solid;
    width: 20px;
    height: 20px;
}
.large-loading {
    border: 10px solid;
    border-top: 10px solid;
    width: 100px;
    height: 100px;
}

.loading {
    border-radius: 50%;
    animation: spin 2s linear infinite;
    margin-right: auto;
    margin-left: auto;
    border-color: var(--loading-border-color);
    border-top-color: var(--loading-border-top-color);
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (min-width: 768px) { 
}
@media (min-width: 1024px) { 
}