:root {

    // ====================================== Footer ======================================

    // Colors
    --footer-ssl-background-color: var(--primary-color);

    // Mobile Padding
    --mobile-footer-ssl-padding-top: var(--mobile-cyan-spacing);
    --mobile-footer-ssl-padding-bottom: var(--mobile-cyan-spacing);

    // Tablet Padding
    --tablet-footer-ssl-padding-top: var(--tablet-cyan-spacing);
    --tablet-footer-ssl-padding-bottom: var(--tablet-cyan-spacing);

    // Desktop Padding
    --desktop-footer-ssl-padding-top: var(--desktop-cyan-spacing);
    --desktop-footer-ssl-padding-bottom: var(--desktop-cyan-spacing);

    // =================================== Footer Image ===================================

    // Mobile Width/Height
    --mobile-footer-ssl-image-footer-width: 210px;
    --mobile-footer-ssl-image-footer-height: 46px;

    // Tablet Width/Height
    --tablet-footer-ssl-image-footer-width: 210px;
    --tablet-footer-ssl-image-footer-height: 46px;

    // Desktop Width/Height
    --desktop-footer-ssl-image-footer-width: 210px;
    --desktop-footer-ssl-image-footer-height: 46px;

    // =================================== Footer Text ====================================

    // Colors
    --footer-ssl-text-footer-text-color: var(--footer-text-color);

    // Fonts
    --footer-ssl-text-footer-font-family: var(--main-font);

    // Mobile Fonts
    --mobile-footer-ssl-text-footer-font-size: var(--mobile-small-font-size);
    --mobile-footer-ssl-text-footer-line-height: var(--mobile-small-line-height);
    --mobile-footer-ssl-text-footer-letter-spacing: var(--mobile-small-letter-spacing);
    --mobile-footer-ssl-text-footer-font-weight: var(--mobile-small-font-weight);

    // Tablet/Desktop Fonts
    --tablet-footer-ssl-text-footer-font-size: var(--tablet-small-font-size);
    --tablet-footer-ssl-text-footer-line-height: var(--tablet-small-line-height);
    --tablet-footer-ssl-text-footer-letter-spacing: var(--tablet-small-letter-spacing);
    --tablet-footer-ssl-text-footer-font-weight: var(--tablet-small-font-weight);

    // Padding
    --mobile-footer-ssl-text-footer-padding-top: var(--mobile-green-spacing);
    --tablet-footer-ssl-text-footer-padding-top: var(--tablet-green-spacing);
    --desktop-footer-ssl-text-footer-padding-top: var(--desktop-green-spacing);
}