// Mobile
.container-ProgressStepper {
    display: inline-flex;
    width: 100%;
    gap: var(--mobile-ProgressStepper-container-gap);
    align-items: center;
    justify-content: center;
}

.circle-ProgressStepper {
    width: 14px;
    height: 14px;
    border-radius: 50%;
}

.bar-ProgressStepper {
    height: 14px;
    width: auto;
    flex-grow: 1;
    border-radius: 12px;
}

.filled-bar-ProgressStepper {
    background: var(--ProgressStepper-filled-background-color);
    border: 2px solid var(--ProgressStepper-filled-border-color);
}

.filled-circle-ProgressStepper {
    background: var(--ProgressStepper-filled-circle-background-color);
    border: 2px solid var(--ProgressStepper-filled-circle-border-color);
}
.current-circle-ProgressStepper {
    background: var(--ProgressStepper-current-circle-background-color);
    border: 2px solid var(--ProgressStepper-current-circle-border-color);
}

.outlined-bar-ProgressStepper {
    border: 2px solid var(--ProgressStepper-outlined-bar-border-color);
}

.outlined-circle-ProgressStepper {
    border: 2px solid var(--ProgressStepper-outlined-circle-border-color);
}

// Tablet
@media (min-width: 768px) { 
    .progress-bar-container {
        gap: var(--tablet-ProgressStepper-container-gap);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .progress-bar-container {
        gap: var(--desktop-ProgressStepper-container-gap);
    }
}