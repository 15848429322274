:root {

    // ============================= Blocker ================================

    // Colors
    --blocker-popup-background-color: var(--blocker-color);

    // ============================== Popup =================================

    // Colors
    --popup-background-color: var(--white);

    // Mobile Padding
    --mobile-popup-padding-top: var(--mobile-pink-spacing);
    --mobile-popup-padding-right: var(--mobile-red-spacing);
    --mobile-popup-padding-left: var(--mobile-red-spacing);

    // Desktop Padding
    --tablet-popup-padding-top: var(--tablet-pink-spacing);

    // Desktop Padding
    --desktop-popup-padding-top: var(--desktop-pink-spacing);

    // ============================= Header ================================

    // Colors
    --header-popup-border-color: var(--default-border);

    // Margins/Paddings
    --tablet-header-popup-padding-bottom: var(--tablet-red-spacing);
    --tablet-header-popup-margin-bottom: var(--tablet-cyan-spacing);
    --desktop-header-popup-padding-bottom: var(--desktop-red-spacing);
    --desktop-header-popup-margin-bottom: var(--desktop-cyan-spacing);

}