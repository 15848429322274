:root {
    --mobile-logo-image-logo-width: var(--mobile-logo-width);
    --tablet-logo-image-logo-width: var(--tablet-logo-width);
    --desktop-logo-image-logo-width: var(--desktop-logo-width);
    --mobile-logo-image-logo-min-width: var(--mobile-logo-min-width);
    --mobile-logo-image-logo-max-width: var(--mobile-logo-max-width);
    --tablet-logo-image-logo-min-width: var(--tablet-logo-min-width);
    --tablet-logo-image-logo-max-width: var(--tablet-logo-max-width);
    --desktop-logo-image-logo-min-width: var(--desktop-logo-min-width);
    --desktop-logo-image-logo-max-width: var(--desktop-logo-max-width);
}