// Mobile
.text-header {
    font-family: var(--header-text-header-font-family);
    font-size: var(--mobile-header-text-header-font-size);
    line-height: var(--mobile-header-text-header-line-height);
    letter-spacing: var(--mobile-header-text-header-letter-spacing);
    font-weight: var(--mobile-header-text-header-font-weight);
    margin-top: 0;
    margin-bottom: 0;
}

// Tablet
@media (min-width: 768px) { 
    .text-header {
        font-size: var(--header-text-header-font-size);
        line-height: var(--header-text-header-line-height);
        letter-spacing: var(--header-text-header-letter-spacing);
        font-weight: var(--header-text-header-font-weight);
    }
}

// Desktop
@media (min-width: 1024px) { 
}