// Mobile
.container-step {
    height: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

// Tablet
@media (min-width: 768px) { 
}

// Desktop
@media (min-width: 1024px) { 
}