// Mobile
.container-stepper-buttons-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: var(--mobile-container-stepper-buttons-container-gap);
}

// Tablet
@media (min-width: 768px) { 
    .container-stepper-buttons-container {
        flex-direction: row-reverse;
        gap: var(--tablet-container-stepper-buttons-container-gap);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .container-stepper-buttons-container {
        gap: var(--desktop-container-stepper-buttons-container-gap);
    }
}