:root {

    // ================================== Container ==================================

    --mobile-ProgressStepper-container-gap: var(--mobile-green-spacing);
    --tablet-ProgressStepper-container-gap: var(--tablet-green-spacing);
    --desktop-ProgressStepper-container-gap: var(--desktop-green-spacing);

    // ================================= Bar (Filled) ================================

    --ProgressStepper-filled-background-color: var(--filled-bar-color);
    --ProgressStepper-filled-border-color: var(--filled-bar-color);

    // ================================ Circle (Current) =============================

    --ProgressStepper-current-circle-background-color: var(--current-circle-color);
    --ProgressStepper-current-circle-border-color: var(--current-circle-color);

    // ================================ Circle (Filled) ==============================

    --ProgressStepper-filled-circle-background-color: var(--filled-circle-color);
    --ProgressStepper-filled-circle-border-color: var(--filled-circle-color);

    // ================================= Bar (Outlined) ==============================

    --ProgressStepper-outlined-bar-border-color: var(--outlined-bar-color);

    // ================================ Circle (Outlined) =============================

    --ProgressStepper-outlined-circle-border-color: var(--outlined-circle-color);

}