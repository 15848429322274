:root {

    // ==================================== HeaderText ====================================

    // Fonts
    --header-text-header-font-family: var(--header-font);

    // Mobile Fonts
    --mobile-header-text-header-font-size: var(--mobile-header-font-size);
    --mobile-header-text-header-line-height: var(--mobile-header-line-height);
    --mobile-header-text-header-letter-spacing: var(--mobile-header-letter-spacing);
    --mobile-header-text-header-font-weight: var(--mobile-header-font-weight);

    // Tablet/Desktop Fonts
    --header-text-header-font-size: var(--tablet-header-font-size);
    --header-text-header-line-height: var(--tablet-header-line-height);
    --header-text-header-letter-spacing: var(--tablet-header-letter-spacing);
    --header-text-header-font-weight: var(--tablet-header-font-weight);
}