// Mobile
.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    background-color: var(--container-container-background-color);
    min-height: 100%;
}

// Tablet
@media (min-width: 768px) { 
}

// Desktop
@media (min-width: 1024px) { 
    .container {
        width: 40vw;
        min-width: 750px;
        border-radius: 30px;
        margin-top: var(--desktop-container-container-margin-top);
        margin-bottom: var(--desktop-container-container-margin-bottom);
    }
}