// Mobile
.progressbar-container {
    width: 100%;
    height: 18px;
    border: 2px solid var(--progressbar-container-border-color);
    background-color: var(--progressbar-container-background-color);
    border-radius: 30px;
    position: relative;
}

.progressbar-progress-indicator {
    width: 41px;
    height: 40px;
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: calc(0px - 22px);
    top: 0; left: 0; bottom: 0; right: 0;
}

.progressbar-innerBar {
    height: 16px;
    width: 0%;
    border-radius: 30px;
    border: 1px solid var(--progressbar-innerBar-border-color);
    background: var(--progressbar-innerBar-background-color);
}

// Tablet
@media (min-width: 768px) { 

}

// Desktop
@media (min-width: 1024px) { 

}