:root {

    // =========================== Colors ==============================

    // Mobile Margins
    --mobile-headingtext-text-heading-margin-top: var(--mobile-pink-spacing);
    --mobile-headingtext-text-heading-margin-bottom: var(--mobile-blue-spacing);

    // Tablet Margins
    --tablet-headingtext-text-heading-margin-top: var(--tablet-cyan-spacing);
    --tablet-headingtext-text-heading-margin-bottom: var(--tablet-blue-spacing);

    // Desktop Margins
    --desktop-headingtext-text-heading-margin-top: var(--desktop-cyan-spacing);
    --desktop-headingtext-text-heading-margin-bottom: var(--desktop-blue-spacing);
    
}