// Mobile
.filled-button {
    cursor: pointer;
    height: 50px;
    color: var(--button-filled-button-text-color);
    background-color: var(--button-filled-button-background-color);

    font-family: var(--button-filled-button-font-family);
    font-size: var(--mobile-button-filled-button-font-size);
    line-height: var(--mobile-button-filled-button-line-height);
    letter-spacing: var(--mobile-button-filled-button-letter-spacing);
    font-weight: var(--mobile-button-filled-button-font-weight);

    border: 2px solid var(--button-filled-button-border-color);
    border-radius: 39px;
}
.filled-button:active {
    background-color: var(--button-filled-button-background-color-active);
    border-color: var(--button-filled-button-border-color-active);
}
.filled-button:hover {
    background-color: var(--button-filled-button-background-color-hover);
    border-color: var(--button-filled-button-border-color-hover);
}

.outlined-button {
    cursor: pointer;
    height: 50px;
    color: var(--button-outlined-button-text-color);
    background-color: var(--button-outlined-button-background-color);

    font-family: var(--button-outlined-button-font-family);
    font-size: var(--mobile-button-outlined-button-font-size);
    line-height: var(--mobile-button-outlined-button-line-height);
    letter-spacing: var(--mobile-button-outlined-button-letter-spacing);
    font-weight: var(--mobile-button-outlined-button-font-weight);

    border: 2px solid var(--button-outlined-button-border-color);
    border-radius: 39px;
}
.outlined-button:active {
    color: var(--button-outlined-button-text-color-active);
    background-color: var(--button-outlined-button-background-color-active);
    border-color: var(--button-outlined-button-border-color-active);
}
.outlined-button:hover {
    color: var(--button-outlined-button-text-color-hover);
    background-color: var(--button-outlined-button-background-color-hover);
    border-color: var(--button-outlined-button-border-color-hover);
}

.next-button {
    width: 100%;
    margin-top: var(--mobile-button-next-button-margin-top);
}
.lone-next-button {
    width: 100%;
    margin-top: var(--mobile-button-lone-next-button-margin-top);
    margin-bottom: var(--mobile-button-lone-next-button-margin-bottom);
}
.previous-button {
    width: 100%;
    margin-top: var(--mobile-button-previous-button-margin-top);
    margin-bottom: var(--mobile-button-previous-button-margin-bottom);
}

.label-button-div {
    width: 100%;
    margin-top: var(--mobile-button-label-button-div-margin-top);
}
.label-button-label {
    width: 100%;
    text-align: center;
    margin-top: var(--mobile-button-label-button-label-margin-top);
    margin-bottom: var(--mobile-button-label-button-label-margin-bottom);
}
.label-button-button {
    width: 100%;
}
.next-button-no-bottom-margin {
    width: 100%;
    margin-top: var(--mobile-next-button-no-bottom-margin-margin-top);
}
.disclosures-previous-button {
    width: 100%;
    margin-top: var(--mobile-disclosures-previous-button-margin-top);
    margin-bottom: var(--mobile-disclosures-previous-button-margin-bottom);
}

.disclosures-next-button {
    width: 100%;
    margin-top: var(--mobile-disclosures-next-button-margin-top);
    margin-bottom: var(--mobile-disclosures-next-button-margin-bottom);
}

// Tablet
@media (min-width: 768px) { 
    .filled-button {
        font-size: var(--tablet-button-filled-button-font-size);
        line-height: var(--tablet-button-filled-button-line-height);
        letter-spacing: var(--tablet-button-filled-button-letter-spacing);
        font-weight: var(--tablet-button-filled-button-font-weight);
    }

    .outlined-button {
        font-size: var(--tablet-button-outlined-button-font-size);
        line-height: var(--tablet-button-outlined-button-line-height);
        letter-spacing: var(--tablet-button-outlined-button-letter-spacing);
        font-weight: var(--tablet-button-outlined-button-font-weight);
    }

    .next-button {
        margin-top: var(--tablet-button-next-button-margin-top);
        margin-bottom: var(--tablet-button-next-button-margin-bottom);
    }
    .lone-next-button {
        margin-top: var(--tablet-button-lone-next-button-margin-top);
        margin-bottom: var(--tablet-button-lone-next-button-margin-bottom);
    }
    .previous-button {
        margin-top: var(--tablet-button-previous-button-margin-top);
        margin-bottom: var(--tablet-button-previous-button-margin-bottom);
    }

    .label-button-div {
        margin-top: var(--tablet-button-label-button-div-margin-top);
    }
    .label-button-label {
        margin-bottom: var(--tablet-button-label-button-label-margin-bottom);
    }

    .next-button-no-bottom-margin {
        margin-top: var(--tablet-next-button-no-bottom-margin-margin-top);
    }

    .disclosures-previous-button {
        margin-top: var(--tablet-disclosures-previous-button-margin-top);
        margin-bottom: var(--tablet-disclosures-previous-button-margin-bottom);
    }

    .disclosures-next-button {
        margin-top: var(--tablet-disclosures-next-button-margin-top);
        margin-bottom: var(--tablet-disclosures-next-button-margin-bottom);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .next-button {
        margin-top: var(--desktop-button-next-button-margin-top);
        margin-bottom: var(--desktop-button-next-button-margin-bottom);
    }
    .lone-next-button {
        margin-top: var(--desktop-button-lone-next-button-margin-top);
        margin-bottom: var(--desktop-button-lone-next-button-margin-bottom);
    }
    .previous-button {
        margin-top: var(--desktop-button-previous-button-margin-top);
        margin-bottom: var(--desktop-button-previous-button-margin-bottom);
    }

    .label-button-div {
        margin-top: var(--desktop-button-label-button-div-margin-top);
    }
    .label-button-label {
        margin-bottom: var(--desktop-button-label-button-label-margin-bottom);
    }

    .next-button-no-bottom-margin {
        margin-top: var(--desktop-next-button-no-bottom-margin-margin-top);
    }

    .disclosures-previous-button {
        margin-top: var(--desktop-disclosures-previous-button-margin-top);
        margin-bottom: var(--desktop-disclosures-previous-button-margin-bottom);
    }

    .disclosures-next-button {
        margin-top: var(--desktop-disclosures-next-button-margin-top);
        margin-bottom: var(--desktop-disclosures-next-button-margin-bottom);
    }
}