:root {

    // ============ Spacing ============

    // Mobile
    // --mobile-blue-spacing: 1vw;
    // --mobile-green-spacing: 2vw;
    // --mobile-pink-spacing: 3vw;
    // --mobile-cyan-spacing: 4vw;
    // --mobile-red-spacing: 6vw;
    // --mobile-purple-spacing: 9vw;
    // --mobile-sea-green-spacing: 12vw;
    --mobile-blue-spacing: 4px;
    --mobile-green-spacing: 8px;
    --mobile-pink-spacing: 12px;
    --mobile-cyan-spacing: 16px;
    --mobile-red-spacing: 24px;
    --mobile-purple-spacing: 32px;
    --mobile-sea-green-spacing: 48px;

    // Tablet
    --tablet-blue-spacing: 4px;
    --tablet-green-spacing: 8px;
    --tablet-pink-spacing: 12px;
    --tablet-cyan-spacing: 16px;
    --tablet-red-spacing: 24px;
    --tablet-purple-spacing: 32px;
    --tablet-sea-green-spacing: 48px;

    // Desktop
    --desktop-blue-spacing: 4px;
    --desktop-green-spacing: 8px;
    --desktop-pink-spacing: 12px;
    --desktop-cyan-spacing: 16px;
    --desktop-red-spacing: 24px;
    --desktop-purple-spacing: 32px;
    --desktop-sea-green-spacing: 48px;

}