// Mobile
.page {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
}

// Tablet
@media (min-width: 768px) { 
}

// Desktop
@media (min-width: 1024px) { 
    .page {
        align-items: center;
        justify-content: center;
        background: linear-gradient(180deg, var(--page-background-start-color) 20%, var(--page-background-end-color) 100%);
    }
}