:root {

    // =================================== Filled Button ==================================

    // Colors
    --button-filled-button-text-color: var(--button-text-color);
    --button-filled-button-background-color: var(--primary-color);
    --button-filled-button-border-color: var(--primary-color);
    --button-filled-button-text-color-active: var(--button-text-color);
    --button-filled-button-text-color-hover: var(--button-text-color);

    // Fonts
    --button-filled-button-font-family: var(--bold-font);

    // Active State
    --button-filled-button-background-color-active: var(--active-color);
    --button-filled-button-border-color-active: var(--active-color);

    // Hover
    --button-filled-button-background-color-hover: var(--hover-color);
    --button-filled-button-border-color-hover: var(--hover-color);

    // Mobile Fonts
    --mobile-button-filled-button-font-size: var(--mobile-button-font-size);
    --mobile-button-filled-button-line-height: var(--mobile-button-line-height);
    --mobile-button-filled-button-letter-spacing: var(--mobile-button-letter-spacing);
    --mobile-button-filled-button-font-weight: var(--mobile-button-font-weight);

    // Tablet/Desktop Fonts
    --tablet-button-filled-button-font-size: var(--tablet-button-font-size);
    --tablet-button-filled-button-line-height: var(--tablet-button-line-height);
    --tablet-button-filled-button-letter-spacing: var(--tablet-button-letter-spacing);
    --tablet-button-filled-button-font-weight: var(--tablet-button-font-weight);

    // =================================== Outlined Button ================================

    // Colors
    --button-outlined-button-text-color: var(--primary-color);
    --button-outlined-button-background-color: rgba(0, 0, 0, 0);
    --button-outlined-button-border-color: var(--primary-color);

    // Fonts
    --button-outlined-button-font-family: var(--bold-font);

    // Active State
    --button-outlined-button-text-color-active: var(--button-text-color);
    --button-outlined-button-background-color-active: var(--active-color);
    --button-outlined-button-border-color-active: var(--active-color);

    // Hover State
    --button-outlined-button-text-color-hover: var(--button-text-color);
    --button-outlined-button-background-color-hover: var(--hover-color);
    --button-outlined-button-border-color-hover: var(--hover-color);

    // Mobile Fonts
    --mobile-button-outlined-button-font-size: var(--mobile-button-font-size);
    --mobile-button-outlined-button-line-height: var(--mobile-button-line-height);
    --mobile-button-outlined-button-letter-spacing: var(--mobile-button-letter-spacing);
    --mobile-button-outlined-button-font-weight: var(--mobile-button-font-weight);

    // Tablet/Desktop Fonts
    --tablet-button-outlined-button-font-size: var(--tablet-button-font-size);
    --tablet-button-outlined-button-line-height: var(--tablet-button-line-height);
    --tablet-button-outlined-button-letter-spacing: var(--tablet-button-letter-spacing);
    --tablet-button-outlined-button-font-weight: var(--tablet-button-font-weight);

    // =================================== Next Button ===================================

    // This Button will also have all styling from Filled Button

    // Mobile Margin
    --mobile-button-next-button-margin-top: var(--mobile-red-spacing);

    // Tablet Margin
    --tablet-button-next-button-margin-top: var(--tablet-purple-spacing);
    --tablet-button-next-button-margin-bottom: var(--tablet-sea-green-spacing);

    // Desktop Margin
    --desktop-button-next-button-margin-top: var(--desktop-purple-spacing);
    --desktop-button-next-button-margin-bottom: var(--desktop-sea-green-spacing);

    // ================================= Lone Next Button ================================

    // This Button will also have all styling from Filled Button

    // Mobile Margin
    --mobile-button-lone-next-button-margin-top: var(--mobile-red-spacing);  
    --mobile-button-lone-next-button-margin-bottom: var(--mobile-purple-spacing);

    // Tablet Margin
    --tablet-button-lone-next-button-margin-top: var(--tablet-purple-spacing);
    --tablet-button-lone-next-button-margin-bottom: var(--tablet-sea-green-spacing);

    // Desktop Margin
    --desktop-button-lone-next-button-margin-top: var(--desktop-purple-spacing);
    --desktop-button-lone-next-button-margin-bottom: var(--desktop-sea-green-spacing);

    // ================================== Previous Button ==================================

    // This Button will also have all styling from Outlined Button

    // Mobile Margin
    --mobile-button-previous-button-margin-top: var(--mobile-green-spacing);
    --mobile-button-previous-button-margin-bottom: var(--mobile-purple-spacing);

    // Tablet Margin
    --tablet-button-previous-button-margin-top: var(--tablet-purple-spacing);
    --tablet-button-previous-button-margin-bottom: var(--tablet-sea-green-spacing);

    // Desktop Margin
    --desktop-button-previous-button-margin-top: var(--desktop-purple-spacing);
    --desktop-button-previous-button-margin-bottom: var(--desktop-sea-green-spacing);

    // =================================== Label Button ====================================

    // Div
    --mobile-button-label-button-div-margin-top: var(--mobile-green-spacing);
    --tablet-button-label-button-div-margin-top: var(--tablet-pink-spacing);
    --desktop-button-label-button-div-margin-top: var(--desktop-pink-spacing);

    // Label
    --mobile-button-label-button-label-margin-top: 0;
    --mobile-button-label-button-label-margin-bottom: var(--mobile-blue-spacing);
    --tablet-button-label-button-label-margin-bottom: var(--tablet-green-spacing);
    --desktop-button-label-button-label-margin-bottom: var(--desktop-green-spacing);

    // =========================== Next Button No Bottom Margin ============================

    --mobile-next-button-no-bottom-margin-margin-top: var(--mobile-red-spacing);
    --tablet-next-button-no-bottom-margin-margin-top: var(--tablet-purple-spacing);
    --desktop-next-button-no-bottom-margin-margin-top: var(--desktop-purple-spacing);

    // =============================== Disclosures Previous ================================

    --mobile-disclosures-previous-button-margin-top: var(--mobile-green-spacing);
    --mobile-disclosures-previous-button-margin-bottom: var(--mobile-purple-spacing);
    --tablet-disclosures-previous-button-margin-top: var(--tablet-pink-spacing);
    --tablet-disclosures-previous-button-margin-bottom: var(--tablet-sea-green-spacing);
    --desktop-disclosures-previous-button-margin-top: var(--desktop-pink-spacing);
    --desktop-disclosures-previous-button-margin-bottom: var(--desktop-sea-green-spacing);

    // ================================= Disclosures Next ==================================

    --mobile-disclosures-next-button-margin-top: var(--mobile-red-spacing);
    --mobile-disclosures-next-button-margin-bottom: 0;
    --tablet-disclosures-next-button-margin-top: 0;
    --tablet-disclosures-next-button-margin-bottom: 0;
    --desktop-disclosures-next-button-margin-top: 0;
    --desktop-disclosures-next-button-margin-bottom: 0;

}