// Mobile
.main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: var(--mobile-main-main-padding-left);
    padding-right: var(--mobile-main-main-padding-right);
}

// Tablet
@media (min-width: 768px) { 
    .main {
        padding-left: var(--tablet-main-main-padding-left);
        padding-right: var(--tablet-main-main-padding-right);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .main {
        padding-left: var(--desktop-main-main-padding-left);
        padding-right: var(--desktop-main-main-padding-right);
    }
}