// Mobile
.text-subheading {
    margin-bottom: var(--mobile-subheadingtext-text-subheading-margin-bottom);
}

// Tablet
@media (min-width: 768px) { 
    .text-subheading {
        margin-bottom: var(--tablet-subheadingtext-text-subheading-margin-bottom);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .text-subheading {
        margin-bottom: var(--desktop-subheadingtext-text-subheading-margin-bottom);
    }
}