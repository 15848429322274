:root {

    // ======== Super Heading (SuperHeaderText) ========

    // Mobile
    --mobile-super-header-font-size: 30px;
    --mobile-super-header-line-height: 42px;
    --mobile-super-header-letter-spacing: 2%;
    --mobile-super-header-font-weight: 600;

    //Tablet/Desktop
    --tablet-super-header-font-size: 38px;
    --tablet-super-header-line-height: 50px;
    --tablet-super-header-letter-spacing: 2%;
    --tablet-super-header-font-weight: 600;

    // ============= Heading (HeaderText) =============

    // Mobile
    --mobile-header-font-size: 24px;
    --mobile-header-line-height: 32px;
    --mobile-header-letter-spacing: 2%;
    --mobile-header-font-weight: 600;

    //Tablet/Desktop
    --tablet-header-font-size: 30px;
    --tablet-header-line-height: 42px;
    --tablet-header-letter-spacing: 2%;
    --tablet-header-font-weight: 600;

    // ==================== Button ====================

    // Mobile
    --mobile-button-font-size: 16px;
    --mobile-button-line-height: 16px;
    --mobile-button-letter-spacing: 0%;
    --mobile-button-font-weight: 700;

    // Tablet/Desktop
    --tablet-button-font-size: 18px;
    --tablet-button-line-height: 18px;
    --tablet-button-letter-spacing: 0%;
    --tablet-button-font-weight: 700;

    // ================ Input (TextField) =============

    // Mobile
    --mobile-input-font-size: 16px;
    --mobile-input-line-height: 24px;
    --mobile-input-letter-spacing: 0%;
    --mobile-input-font-weight: 500;

    // Tablet/Desktop
    --tablet-input-font-size: 18px;
    --tablet-input-line-height: 26px;
    --tablet-input-letter-spacing: 0%;
    --tablet-input-font-weight: 500;

    // ======== Field Title (TextField Label) =========

    // Mobile
    --mobile-label-font-size: 16px;
    --mobile-label-line-height: 24px;
    --mobile-label-letter-spacing: 0%;
    --mobile-label-font-weight: 700;

    // Tablet/Desktop
    --tablet-label-font-size: 18px;
    --tablet-label-line-height: 26px;
    --tablet-label-letter-spacing: 0%;
    --tablet-label-font-weight: 700;

    // ============= Paragraph (BodyText) =============

    // Mobile
    --mobile-body-font-size: 14px;
    --mobile-body-line-height: 20px;
    --mobile-body-letter-spacing: 0%;
    --mobile-body-font-weight: 400;

    // Tablet/Desktop
    --tablet-body-font-size: 16px;
    --tablet-body-line-height: 22px;
    --tablet-body-letter-spacing: 0%;
    --tablet-body-font-weight: 400;

    // =========== Paragraph Bold (BodyText) ==========

    // Mobile
    --mobile-body-bold-font-size: 14px;
    --mobile-body-bold-line-height: 20px;
    --mobile-body-bold-letter-spacing: 0%;
    --mobile-body-bold-font-weight: 700;

    // Tablet/Desktop
    --tablet-body-bold-font-size: 16px;
    --tablet-body-bold-line-height: 22px;
    --tablet-body-bold-letter-spacing: 0%;
    --tablet-body-bold-font-weight: 700;

    // ============== Small (SmallText) ===============

    // Mobile
    --mobile-small-font-size: 12px;
    --mobile-small-line-height: 14px;
    --mobile-small-letter-spacing: 0%;
    --mobile-small-font-weight: 400;

    // Tablet/Desktop
    --tablet-small-font-size: 14px;
    --tablet-small-line-height: 16px;
    --tablet-small-letter-spacing: 0%;
    --tablet-small-font-weight: 400;

    // ============== Legal (LegalText) ===============

    // Mobile
    --mobile-legal-font-size: 10px;
    --mobile-legal-line-height: 12px;
    --mobile-legal-letter-spacing: 0%;
    --mobile-legal-font-weight: 300;

    // Tablet/Desktop
    --tablet-legal-font-size: 12px;
    --tablet-legal-line-height: 14px;
    --tablet-legal-letter-spacing: 0%;
    --tablet-legal-font-weight: 300;

    // ==================== Misc ======================

    // Styles
    --normal-font-style: normal;

}