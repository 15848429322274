// Mobile
.smalltext {
    margin: 0px;
    font-family: var(--smalltext-font-family);
    color: var(--smalltext-text-color);
    font-size: var(--mobile-smalltext-font-size);
    line-height: var(--mobile-smalltext-line-height);
    letter-spacing: var(--mobile-smalltext-letter-spacing);
    font-weight: var(--mobile-smalltext-font-weight);
}

// Tablet
@media (min-width: 768px) { 
    .smalltext {
        font-size: var(--tablet-smalltext-font-size);
        line-height: var(--tablet-smalltext-line-height);
        letter-spacing: var(--tablet-smalltext-letter-spacing);
        font-weight: var(--tablet-smalltext-font-weight);
    }
}

// Desktop
@media (min-width: 1024px) { 
}