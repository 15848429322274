:root {

    // ============= Colors =============

    // Main
    --primary-color: #6562ff;
    --secondary-color: #3331a0;
    --tertiary-color: #35ce5c;
    --white: #fff;
    --black: #000;
    --demo-form-gray: #918e8e;
    --fail-state: #ff0000;

    // Background
    --bg-start-color: #EBEBEB;
    --bg-end-color: #CFCFCF;

    // Text
    --text-color: var(--black);
    --header-text-color: var(--black);
    --label-text-color: var(--black);
    --button-text-color: var(--white);
    --footer-text-color: var(--white);
    --error-text-color: var(--fail-state);
    --placeholder-text-color: var(--demo-form-gray);
    --hyperlink-text-color: #0D01F2;

    // Border Colors
    --default-border: var(--demo-form-gray);
    --error-border: var(--fail-state);

    // States
    --hover-color: var(--secondary-color);
    --active-color: var(--secondary-color);

    // Progress Bar
    --current-circle-color: var(--secondary-color);
    --filled-circle-color: var(--primary-color);
    --outlined-circle-color: #C4C5C9;
    --filled-bar-color: var(--primary-color);
    --outlined-bar-color: #808285;

    // Misc
    --accent-color: var(--primary-color);
    --checkmark-color: var(--white);
    --scroll-bar-color: var(--primary-color);
    --scroll-bar-color-hover: var(--secondary-color);
    --loading-fill-color: var(--primary-color);
    --loading-outline-color: #CFCFCF;
    --blocker-color: #808285;

    // ============= Fonts =============

    // Families
    --main-font: 'Gotham';
    --bold-font: 'Gotham Bold';
    --font-500: "Gotham";
    --font-600: "Gotham Bold";
    --legal-font: "Gotham";
    --header-font: 'Gotham Bold';

    // ============= Logo =============

    // Dimesnions
    --mobile-logo-width: 28vw;
    --tablet-logo-width: 36vw;
    --desktop-logo-width: 14vw;

}