// Mobile
.image-logo {
    width: var(--mobile-logo-image-logo-width);
    min-width: var(--mobile-logo-image-logo-min-width);
    max-width: var(--mobile-logo-image-logo-max-width);
}

// Tablet
@media (min-width: 768px) { 
    .image-logo {
        width: var(--tablet-logo-image-logo-width);
        min-width: var(--tablet-logo-image-logo-min-width);
        max-width: var(--tablet-logo-image-logo-max-width);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .image-logo {
        width: var(--desktop-logo-image-logo-width);
        min-width: var(--desktop-logo-image-logo-min-width);
        max-width: var(--desktop-logo-image-logo-max-width);
    }
}