// Mobile
.header {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: var(--mobile-header-header-margin-top);
    margin-bottom: var(--mobile-header-header-margin-bottom);
}

.header-colored {
    width: 100%;
    display: flex;
    background-color: var(--header-header-colored-background-color);
    justify-content: center;
    padding-top: var(--mobile-header-header-colored-padding-top);
    padding-bottom: var(--mobile-header-header-colored-padding-bottom);
    margin-bottom: var(--mobile-header-header-colored-margin-bottom);
}

// Tablet
@media (min-width: 768px) { 
    .header {
        margin-top: var(--tablet-header-header-margin-top);
        margin-bottom: var(--tablet-header-header-margin-bottom);
    }

    .header-colored {
        padding-top: var(--tablet-header-header-colored-padding-top);
        padding-bottom: var(--tablet-header-header-colored-padding-bottom);
        margin-bottom: var(--tablet-header-header-colored-margin-bottom);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .header {
        border-radius: 30px 30px 0px 0px;
        margin-top: var(--desktop-header-header-margin-top);
        margin-bottom: var(--desktop-header-header-margin-bottom);
    }

    .header-colored {
        border-radius: 30px 30px 0px 0px;
        padding-top: var(--desktop-header-header-colored-padding-top);
        padding-bottom: var(--desktop-header-header-colored-padding-bottom);
        margin-bottom: var(--desktop-header-header-colored-margin-bottom);
    }
}