:root {

    // ============= Small Text =============

    // Colors
    --smalltext-text-color: var(--text-color);

    // Fonts
    --smalltext-font-family: var(--main-font);

    // Mobile Fonts
    --mobile-smalltext-font-size: var(--mobile-small-font-size);
    --mobile-smalltext-line-height: var(--mobile-small-line-height);
    --mobile-smalltext-letter-spacing: var(--mobile-small-letter-spacing);
    --mobile-smalltext-font-weight: var(--mobile-small-font-weight);

    // Tablet/Desktop Fonts
    --tablet-smalltext-font-size: var(--tablet-small-font-size);
    --tablet-smalltext-line-height: var(--tablet-small-line-height);
    --tablet-smalltext-letter-spacing: var(--tablet-small-letter-spacing);
    --tablet-smalltext-font-weight: var(--tablet-header-font-weight);

}