// Mobile
.footer-ssl {
    padding-top: var(--mobile-footer-ssl-padding-top);
    padding-bottom: var(--mobile-footer-ssl-padding-bottom);
    width: 100%;
    background-color: var(--footer-ssl-background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.image-footer-ssl {
    width: var(--mobile-footer-ssl-image-footer-width);
    height: var(--mobile-footer-ssl-image-footer-height);
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}

.text-footer-ssl {
    width: fit-content;
    padding-top: var(--mobile-footer-ssl-text-footer-padding-top);
    color: var(--footer-ssl-text-footer-text-color) !important; // Important there to override issued caused by webpack bundling
    margin: 0;
    font-family: var(--footer-ssl-text-footer-font-family);
    font-size: var(--mobile-footer-ssl-text-footer-font-size);
    line-height: var(--mobile-footer-ssl-text-footer-line-height);
    letter-spacing: var(--mobile-footer-ssl-text-footer-letter-spacing);
    font-weight: var(--mobile-footer-ssl-text-footer-font-weight);
}

// Tablet
@media (min-width: 768px) { 
    .footer-ssl {
        padding-top: var(--tablet-footer-ssl-padding-top);
        padding-bottom: var(--tablet-footer-ssl-padding-bottom);
    }
    
    .image-footer-ssl {
        width: var(--tablet-footer-ssl-image-footer-width);
        height: var(--tablet-footer-ssl-image-footer-height);
    }

    .text-footer-ssl {
        padding-top: var(--tablet-footer-ssl-text-footer-padding-top);
        font-size: var(--tablet-footer-ssl-text-footer-font-size);
        line-height: var(--tablet-footer-ssl-text-footer-line-height);
        letter-spacing: var(--tablet-footer-ssl-text-footer-letter-spacing);
        font-weight: var(--tablet-footer-ssl-text-footer-font-weight);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .footer-ssl {
        border-radius: 0px 0px 30px 30px;
        padding-top: var(--desktop-footer-ssl-padding-top);
        padding-bottom: var(--desktop-footer-ssl-padding-bottom);
    }

    .image-footer-ssl {
        width: var(--desktop-footer-ssl-image-footer-width);
        height: var(--desktop-footer-ssl-image-footer-height);
    }

    .text-footer-ssl {
        padding-top: var(--desktop-footer-ssl-text-footer-padding-top);
    }
}