// Mobile
.text-heading {
    margin-top: var(--mobile-headingtext-text-heading-margin-top);
    margin-bottom: var(--mobile-headingtext-text-heading-margin-bottom);
}

// Tablet
@media (min-width: 768px) { 
    .text-heading {
        margin-top: var(--tablet-headingtext-text-heading-margin-top);
        margin-bottom: var(--tablet-headingtext-text-heading-margin-bottom);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .text-heading {
        margin-top: var(--desktop-headingtext-text-heading-margin-top);
        margin-bottom: var(--desktop-headingtext-text-heading-margin-bottom);
    }
}