.thankyou-checkmark {
    margin-top: var(--mobile-thankyou-checkmark-margin-top);
    margin-bottom: var(--mobile-thankyou-checkmark-margin-bottom);
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: 100%;
    filter: invert(72%) sepia(80%) saturate(408%) hue-rotate(49deg) brightness(90%) contrast(87%);

    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@media (min-width: 768px) { 
    .thankyou-checkmark {
        margin-top: var(--tablet-thankyou-checkmark-margin-top);
        margin-bottom: var(--tablet-thankyou-checkmark-margin-bottom);
        width: 100%;
    }
}
@media (min-width: 1024px) { 
    .thankyou-checkmark {
        margin-top: var(--desktop-thankyou-checkmark-margin-top);
        margin-bottom: var(--desktop-thankyou-checkmark-margin-bottom);
        width: 100%;
    }
}