:root {

    // =================================== Container ==================================

    // Colors
    --container-container-background-color: var(--white);

    // Desktop Margin
    --desktop-container-container-margin-top: var(--desktop-sea-green-spacing);
    --desktop-container-container-margin-bottom: var(--desktop-sea-green-spacing);
}