@font-face {
    font-family: 'Gotham Black';
    src: url('./GOTHAM-BLACK.TTF') format('truetype');
}
@font-face {
    font-family: 'Gotham';
    src: url('./GOTHAM-LIGHT.TTF') format('truetype');
}
@font-face {
    font-family: 'Gotham Bold';
    src: url('./GOTHAM-BOLD.TTF') format('truetype');
}