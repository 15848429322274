:root {

    // =================================== Header ==================================
    
    // Mobile Margins
    --mobile-header-header-margin-top: var(--mobile-cyan-spacing);
    --mobile-header-header-margin-bottom: var(--mobile-cyan-spacing);

    // Tablet Margins
    --tablet-header-header-margin-top: var(--tablet-purple-spacing);
    --tablet-header-header-margin-bottom: var(--tablet-purple-spacing);

    // Desktop Margins
    --desktop-header-header-margin-top: var(--desktop-purple-spacing);
    --desktop-header-header-margin-bottom: var(--desktop-purple-spacing);

    // =============================== Header Colored ==============================

    // Colors
    --header-header-colored-background-color: var(--primary-color);

    // Mobile Margins
    --mobile-header-header-colored-margin-bottom: var(--mobile-purple-spacing);

    // Tablet Margins
    --tablet-header-header-colored-margin-bottom: var(--tablet-purple-spacing);

    // Desktop Margins
    --desktop-header-header-colored-margin-bottom: var(--desktop-purple-spacing);

    // Mobile Paddings
    --mobile-header-header-colored-padding-top: var(--mobile-pink-spacing);
    --mobile-header-header-colored-padding-bottom: var(--mobile-pink-spacing);

    // Tablet Paddings
    --tablet-header-header-colored-padding-top: var(--tablet-red-spacing);
    --tablet-header-header-colored-padding-bottom: var(--tablet-red-spacing);

    // Desktop Margins
    --desktop-header-header-colored-padding-top: var(--desktop-red-spacing);
    --desktop-header-header-colored-padding-bottom: var(--desktop-red-spacing);
    
}