:root {

    // ==================================== HeaderText ====================================

    // Fonts
    --text-super-heading-font-family: var(--font-600);

    // Mobile Fonts
    --mobile-text-super-heading-font-size: var(--mobile-super-header-font-size);
    --mobile-text-super-heading-line-height: var(--mobile-super-header-line-height);
    --mobile-text-super-heading-letter-spacing: var(--mobile-super-header-letter-spacing);
    --mobile-text-super-heading-font-weight: var(--mobile-super-header-font-weight);

    // Tablet/Desktop Fonts
    --text-super-heading-font-size: var(--tablet-super-header-font-size);
    --text-super-heading-line-height: var(--tablet-super-header-line-height);
    --text-super-heading-letter-spacing: var(--tablet-super-header-letter-spacing);
    --text-super-heading-font-weight: var(--tablet-super-header-font-weight);
}