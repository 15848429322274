// Mobile
.text-super-heading {
    font-family: var(--text-super-heading-font-family);
    font-size: var(--mobile-text-super-heading-font-size);
    line-height: var(--mobile-text-super-heading-line-height);
    letter-spacing: var(--mobile-text-super-heading-letter-spacing);
    font-weight: var(--mobile-text-super-heading-font-weight);
    margin-top: 0;
    margin-bottom: 0;
}

// Tablet
@media (min-width: 768px) { 
    .text-super-heading {
        font-size: var(--text-super-heading-font-size);
        line-height: var(--text-super-heading-line-height);
        letter-spacing: var(--text-super-heading-letter-spacing);
        font-weight: var(--text-super-heading-font-weight);
    }
}

// Desktop
@media (min-width: 1024px) { 
}