:root {

    // ================================== Container ==================================

    --progressbar-container-border-color: var(--primary-color);
    --progressbar-container-background-color: var(--secondary-color);

    // ================================== Inner Bar ==================================

    --progressbar-innerBar-background-color: var(--primary-color);
    --progressbar-innerBar-border-color: var(--secondary-color);

}